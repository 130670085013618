import autosize from 'autosize';
import { Context, Controller } from '@hotwired/stimulus';

export default class AutosizeController extends Controller {
  constructor(props: Context) {
    super(props);
  }

  connect(): void {
    if (this.element instanceof HTMLTextAreaElement) {
      autosize(this.element);
    }
  }

  disconnect(): void {
    if (this.element instanceof HTMLTextAreaElement) {
      autosize.destroy(this.element);
    }
  }
}
