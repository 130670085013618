import { toggleHidden } from 'src/utils/dom-toggle';
import { Context, Controller } from '@hotwired/stimulus';

const REDUNDANT_CLICK_THRESHOLD = 350;

/**
 * Implements an inclusive method of extending a touch target of a card, but
 * only if JavaScript is enabled. Otherwise, it falls back to an extension
 * element that extends the touch target to the whole card
 *
 * @see https://inclusive-components.design/cards/#theredundantclickevent
 *
 * @example The HTML for a minimal example
 *
 * <div data-controller="accessible-card-link" class="relative">
 *  <nested-content>
 *    <a href="#" data-accessible-card-link-target="link">
 *      <span class="absolute inset-0" data-accessible-card-link-target="extension" />
 *      <span> Label </span>
 *    </a>
 *  </nested-content>
 *
 *  ...
 * </div>
 */
export default class AccessibleCardLinkController extends Controller {
  public static targets = ['link', 'extension'];

  private declare linkTarget: HTMLElement;
  private declare hasLinkTarget: boolean;
  private declare extensionTarget: HTMLElement;
  private declare hasExtensionTarget: boolean;

  private lastClickStart: number = 0;
  private originalTarget: string | null = null;

  public constructor(context: Context) {
    super(context);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  public connect(): void {
    // Remove the extension touch target
    if (this.hasExtensionTarget) {
      toggleHidden(this.extensionTarget, true);
      this.element.classList.add('cursor-pointer');
    }

    if (this.element instanceof HTMLElement) {
      document.addEventListener('keydown', this.onKeyDown);
      document.addEventListener('keyup', this.onKeyUp);

      this.element.addEventListener('mousedown', this.onMouseDown);
      this.element.addEventListener('mouseup', this.onMouseUp);

      // Capture original target
      if (this.hasLinkTarget) {
        this.originalTarget = this.linkTarget.getAttribute('target');
      }
    }
  }

  public disconnect(): void {
    // Add the extension touch target
    if (this.hasExtensionTarget) {
      toggleHidden(this.extensionTarget, false);
      this.element.classList.remove('cursor-pointer');
    }

    if (this.element instanceof HTMLElement) {
      document.removeEventListener('keydown', this.onKeyDown);
      document.removeEventListener('keyup', this.onKeyUp);

      this.element.removeEventListener('mousedown', this.onMouseDown);
      this.element.removeEventListener('mouseup', this.onMouseUp);

      // Restore target
      if (this.hasLinkTarget) {
        if (this.originalTarget === null) {
          this.linkTarget.removeAttribute('target');
        } else {
          this.linkTarget.setAttribute('target', this.originalTarget);
        }
      }

      this.originalTarget = null;
    }
  }

  private onKeyDown(event: KeyboardEvent): void {
    // If none of these are pressed, ignore the keydown
    if (!(event.ctrlKey || event.altKey || event.metaKey)) {
      return;
    }

    if (!this.hasLinkTarget) {
      return;
    }
    this.linkTarget.setAttribute('target', '_blank');
  }

  private onKeyUp(event: KeyboardEvent): void {
    // If any of these still pressed, ignore the keyup
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    if (!this.hasLinkTarget) {
      return;
    }

    if (this.originalTarget === null) {
      this.linkTarget.removeAttribute('target');
    } else {
      this.linkTarget.setAttribute('target', this.originalTarget);
    }
  }

  private onMouseDown(event: MouseEvent): void {
    if (!this.hasLinkTarget || event.target === this.linkTarget) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    this.lastClickStart = new Date().getTime();
  }

  private onMouseUp(event: MouseEvent): void {
    if (!this.hasLinkTarget || event.target === this.linkTarget) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    if (event.target instanceof Element) {
      if (event.target.closest('a, input, button, select, label, [tabindex]')) {
        return;
      }
    }

    const lastClickEnd = new Date().getTime();
    if (lastClickEnd - this.lastClickStart < REDUNDANT_CLICK_THRESHOLD) {
      this.linkTarget.click();
    }
  }
}
