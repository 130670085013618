import { Context, Controller } from '@hotwired/stimulus';

/**
 * Dynamically changes the site access input when options are updated.
 */
export default class SiteAccessController extends Controller {
  public static values = {
    baseDn: String,
    baseLevel: String,
  };

  private declare readonly baseDnValue: string;
  private declare readonly baseLevelValue: string;

  constructor(context: Context) {
    super(context);
  }

  private get fields(): NodeListOf<HTMLSelectElement> {
    return this.element.querySelectorAll(
      'select[data-label][data-distinguished-name]',
    );
  }

  public connect(): void {
    this.update();
  }

  public disconnect(): void {
    this.element.querySelectorAll('[value="-"]').forEach((option) => {
      option.remove();
    });
  }

  private fieldFor(name: string | undefined): HTMLSelectElement | null {
    if (!name) {
      return null;
    }

    return this.element.querySelector<HTMLSelectElement>(
      `select[data-distinguished-name="${name}"]`,
    );
  }

  private valueOf(field: HTMLSelectElement): {
    field: HTMLSelectElement;
    value: string;
  } {
    if (field.value && field.value !== '-') {
      return { field, value: field.value };
    }

    const parent = field
      .getAttribute('data-distinguished-name')
      ?.split('.')
      .slice(0, -1)
      .join('.');

    const parentField = this.fieldFor(parent);
    if (!parentField) {
      return { field, value: '' };
    }

    return this.valueOf(parentField);
  }

  private update(): void {
    this.fields.forEach((field) => {
      const { field: valueField, value } = this.valueOf(field);
      // Remove any disabled field
      field.querySelector('[value="-"]')?.remove();

      if (valueField !== field) {
        if (value === '') {
          field.selectedIndex = 0;
        } else {
          const disabledNode = document.createElement('option');
          disabledNode.setAttribute('value', '-');
          disabledNode.selected = true;

          field.prepend(disabledNode);
          disabledNode.textContent = `${value} → ${valueField.getAttribute(
            'data-label',
          )}`;
        }
      }
    });
  }

  public onChange(): void {
    this.update();
  }
}
